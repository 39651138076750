import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import "../Login.css";
import logo from "../assets/images/logo.svg";
import banner from "../assets/images/login.svg";
import mail from "../assets/icons/mail.svg";
import lock from "../assets/icons/lock.svg";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";

export default function LoginPage() {
  const [email, setEmail] = useState([]);
  const [password, setPassword] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const auth = useAuth();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    auth
      .signIn(email, password)
      .then((response) => {
        setLoading(false);
        navigate("/dashboard");
      })
      .catch((e) => {
        setLoading(false);
        setLoginErrorMessage(e.detail);
      });
  };

  return (
    <div className="flex w-full h-screen">
      <div className="flex">
        <img src={banner} alt="Banner" />
      </div>
      <div className="flex-1 w-full bg-white">
        <div className="flex flex-col justify-center items-center h-screen">
          <div className="text-center">
            <div className="flex justify-center">
              <img src={logo} className="" alt="Logo" />
            </div>
            <h1
              className="font-bold text-2xl py-4"
              style={{
                color: "#000",
                fontFamily: "IBM Plex Sans",
                fontSize: "27px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "normal",
                letterSpacing: "-0.81px",
              }}
            >
              Iniciar sesión
            </h1>

            <form
              onSubmit={handleSubmit}
              className="w-[313px]"
              action="/dashboard/"
            >
              <div className="relative mt-2 rounded-md shadow-sm">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <img src={mail} className="" alt="Mail" />
                </div>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="block w-full rounded-md border-0 py-1.5 pl-10 ml-2 text-gray-900 focus:ring-2 focus:ring-inset focus:ring-[#D2E28A]"
                  placeholder="Tu correo electrónico"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{
                    borderColor: "#D2E28A !important",
                    boxShadow: "0 0 0 1px #D2E28A !important",
                    outline: "none",
                  }}
                />
              </div>

              <div className="relative mt-2 rounded-md shadow-sm">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <img src={lock} className="" alt="Lock" />
                </div>
                <input
                  type={visible ? "text" : "password"}
                  name="password"
                  id="password"
                  className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ml-2 focus:ring-2 focus:ring-inset focus:ring-[#D2E28A]"
                  placeholder="Tu contraseña"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  style={{
                    borderColor: "#D2E28A !important",
                    boxShadow: "0 0 0 1px #D2E28A !important",
                    outline: "none",
                  }}
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 flex items-center px-3"
                  onClick={() => setVisible(!visible)}
                >
                  {visible ? (
                    <EyeSlashIcon
                      className="h-5 w-5 text-gray-400 hover:text-black"
                      aria-hidden="true"
                    />
                  ) : (
                    <EyeIcon
                      className="h-5 w-5 text-gray-400 hover:text-black"
                      aria-hidden="true"
                    />
                  )}
                </button>
              </div>
              {loginErrorMessage && (
                <div
                  className="bg-red-100 mt-4 border border-red-400 text-red-700 px-4 py-1.5 rounded relative"
                  role="alert"
                >
                  <span className="block sm:inline">
                    Credenciales inválidas.
                  </span>
                </div>
              )}

              <div className="text-right mb-5 my-2">
                <a
                  href="/forgot-password/"
                  className="text-s font-normal leading-5 hover:text-gray-900 text-[#8C9C78]"
                >
                  ¿Has olvidado la contraseña?
                </a>
              </div>
              <div className="flex items-center font-bold rounded-lg py-2 px-4 bg-[#D2E28A] w-[313px]">
                <button
                  type="submit"
                  className="flex items-center justify-center w-full"
                >
                  {loading ? (
                    <div className="w-5 h-6 border-b-2 border-white rounded-full animate-spin"></div>
                  ) : (
                    "Iniciar sesión"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
