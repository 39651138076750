import { Menu, Transition } from "@headlessui/react";

import React, { Fragment, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { ReactComponent as LockIcon } from "../assets/icons/lock-keyhole.svg";
import { ReactComponent as ExitIcon } from "../assets/icons/right-from-bracket.svg";
import { useAuth } from "../context/AuthContext";
import ChangePasswordModal from "./ChangePasswordModal";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Profile({ username, profilePicture }) {
  const [showModal, setShowModal] = useState(false);
  const auth = useAuth();

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const userNavigation = [
    {
      name: "Cambiar contraseña",
      icon: <LockIcon />,
      onClick: handleOpenModal,
    },
    {
      name: "Cerrar sesión",
      icon: <ExitIcon />,
      onClick: auth.signOut,
    },
  ];

  return (
    <div>
      {showModal ? (
        <div>
          {showModal && <ChangePasswordModal onClose={handleCloseModal} />}
        </div>
      ) : (
        <div>
          <Menu
            as="div"
            className="flex items-center justify-end p-4 relative mr-7"
          >
            <Menu.Button className="-m-1.5 flex items-center p-1.5">
              <div className="relative w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                {profilePicture ? (
                  <img
                    src={`${process.env.REACT_APP_API_BASE_URL}${profilePicture}`}
                    alt="Profile picture"
                  />
                ) : (
                  <svg
                    className="absolute w-12 h-12 text-gray-400 -left-1"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                )}
                ;
              </div>
              <p className="ml-2 mr-0 text-sm font-semibold tracking-wide">
                {username}
              </p>
              <ChevronDownIcon
                className="ml-2 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-4 top-12 z-10 mt-2.5 w-44 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                {userNavigation.map((item) => (
                  <Menu.Item key={item.name}>
                    {({ active }) => (
                      <button
                        onClick={item.onClick}
                        className={classNames(
                          active ? "bg-gray-50 w-full" : "",
                          "flex items-center justify-start px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer"
                        )}
                      >
                        <div className="mr-2">{item.icon}</div>
                        {item.name}
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      )}
    </div>
  );
}
