export default function BackButton() {
  return (
    <a href="/login" className="block">
      <div className="flex items-center justify-center w-14 h-14 bg-[#F1F1F1] rounded-full">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="27"
          height="27"
          viewBox="0 0 27 27"
          fill="none"
        >
          <path
            d="M20.8704 13.5H6.12939"
            stroke="#111111"
            strokeWidth="2.10586"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.4999 20.8705L6.12939 13.5L13.4999 6.12952"
            stroke="#111111"
            strokeWidth="2.10586"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </a>
  );
}
