import Datepicker from "react-tailwindcss-datepicker";
import "../App.css";

export default function DateFilter({ value, handleValueChange }) {
  return (
    <Datepicker
      containerClassName="relative w-full text-gray-700 h-[38px]"
      toggleClassName="text-[#D2E28A] absolute right-[217px] h-[38px] px-3 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed"
      inputClassName="relative pl-[50px] transition-all duration-300 py-2.5 pl-4 w-[260px] h-[38px] border-gray-300 dark:bg-slate-800 dark:text-white/80 dark:border-slate-600 rounded-lg tracking-wide font-light text-sm placeholder-gray-400 bg-white focus:ring disabled:opacity-40 disabled:cursor-not-allowed focus:border-lime-500 focus:ring-lime-500/20 remove-outline rounded border border-solid border-[#E2E2E2]"
      primaryColor={"lime"}
      i18n={"es"}
      configs={{
        shortcuts: {
          today: "Hoy",
          yesterday: "Ayer",
          past: (period) => `Últimos ${period} días`,
          currentMonth: "Este mes",
          pastMonth: "Mes pasado",
          lastYear: {
            text: "Último año",
            period: {
              start: new Date(
                new Date().setFullYear(new Date().getFullYear() - 1)
              ),
              end: new Date(),
            },
          },
        },
        footer: {
          cancel: "Cancelar",
          apply: "Aplicar",
        },
      }}
      displayFormat={"DD/MM/YYYY"}
      value={value}
      onChange={handleValueChange}
      showShortcuts={true}
      showFooter={true}
    />
  );
}
