import React, { useRef, useEffect, useState } from "react";
import "../App.css";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import api from "../api";

export default function ChangePasswordModal({ onClose }) {
  const passwordInputRef = useRef(null);
  const [visible, setVisible] = useState(false);
  const [password, setPassword] = useState("");
  const [changePasswordError, setChangePasswordError] = useState("");
  const [loadingChangePassword, setLoadingChangePassword] = useState(false);

  useEffect(() => {
    if (passwordInputRef.current) {
      passwordInputRef.current.focus();
    }
  }, []);

  const submitChangePassword = async () => {
    setChangePasswordError("");
    setLoadingChangePassword(true);
    await api.users
      .changePassword(password)
      .then((response) => {
        setLoadingChangePassword(false);
        console.log(response);
        onClose();
      })
      .catch((error) => {
        setLoadingChangePassword(false);
        let msg = "";
        if (error.message) {
          error.message.new.forEach((m) => {
            msg = msg + m + `\n`;
          });
        } else {
          msg = "Hubo un problema al cambiar la contraseña";
        }
        setChangePasswordError(msg);
        console.log(error);
      });
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50">
      <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50">
        <div className="sm:max-w-lg w-full inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:p-6 opacity-100 translate-y-0 sm:scale-100">
          <div>
            <div className="text-center">
              <h3 className="text-lg leading-6 font-semibold text-gray-900">
                Cambiar contraseña
              </h3>
              <div className="mt-2 w-full">
                <label
                  htmlFor="password"
                  className="text-sm font-medium text-gray-700"
                >
                  Nueva contraseña
                </label>
                <div className="relative w-2/3 rounded-md mx-auto shadow-sm border-gray-300">
                  <input
                    ref={passwordInputRef}
                    type={visible ? "text" : "password"}
                    name="password"
                    onChange={(event) => setPassword(event.target.value)}
                    id="password"
                    className="block mt-1 w-full pl-1 h-8 pr-10 sm:text-sm border-gray-300 rounded-md border focus:border-2 focus:border-[#D2E28A]"
                    style={{ outline: "none" }}
                  />
                  <button
                    onClick={() => setVisible(!visible)}
                    className="absolute inset-y-0 right-0 pr-3 flex items-center"
                  >
                    {visible ? (
                      <EyeSlashIcon
                        className="h-5 w-5 text-gray-400 hover:text-[#D2E28A]"
                        aria-hidden="true"
                      />
                    ) : (
                      <EyeIcon
                        className="h-5 w-5 text-gray-400 hover:text-[#D2E28A]"
                        aria-hidden="true"
                      />
                    )}
                  </button>
                </div>
                {!!changePasswordError && (
                  <div className="mt-2 mb-4 text-sm text-red-600">
                    {changePasswordError}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-black hover:bg-primary sm:col-start-2 sm:text-sm bg-d2e28a"
              onClick={() => submitChangePassword()}
            >
              {loadingChangePassword ? (
                <div className="w-5 h-5 mx-5 border-b-2 border-white rounded-full animate-spin"></div>
              ) : (
                "Guardar"
              )}
            </button>
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:col-start-1 sm:text-sm"
              onClick={onClose}
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
