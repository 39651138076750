import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import { useEffect } from "react";
import api from "../api";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function CompanyFilter({ setEmpresaId }) {
  const [companies, setCompanies] = useState(null);
  const [selected, setSelectedCompany] = useState(null);

  useEffect(() => {
    api.companies.getMulti().then((response) => {
      setCompanies(response);
      setSelectedCompany(response[0]);
      console.log(response);
    });
  }, []);

  const handleCompanyChange = (selectedCompany) => {
    setSelectedCompany(selectedCompany);
    setEmpresaId(selectedCompany.id);
  };

  return (
    <Listbox value={selected} onChange={handleCompanyChange}>
      {({ open }) => (
        <>
          <div className="relative">
            {selected && (
              <div className="rounded-lg shadow-sm h-[px]">
                <Listbox.Button
                  className={`inline-flex items-center rounded-lg border border-solid border-[#E2E2E2] bg-white p-2 ${
                    open ? "ring border-lime-500 ring-lime-500/20" : ""
                  }`}
                >
                  <svg
                    className="mr-1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M12.6667 14V3.33333C12.6667 2.59695 12.0697 2 11.3333 2H4.66667C3.93029 2 3.33333 2.59695 3.33333 3.33333V14M12.6667 14L14 14M12.6667 14H9.33333M3.33333 14L2 14M3.33333 14H6.66667M6 4.66665H6.66667M6 7.33332H6.66667M9.33333 4.66665H10M9.33333 7.33332H10M6.66667 14V10.6667C6.66667 10.2985 6.96514 9.99999 7.33333 9.99999H8.66667C9.03486 9.99999 9.33333 10.2985 9.33333 10.6667V14M6.66667 14H9.33333"
                      stroke="#D2E28A"
                      strokeWidth="1.33333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <p className="text-sm font-medium text-[#434343]">
                    {selected.empresa}
                  </p>
                  <ChevronDownIcon
                    className="h-5 w-5 text-[#D2E28A]"
                    aria-hidden="true"
                  />
                </Listbox.Button>
              </div>
            )}

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              {companies && (
                <Listbox.Options className="absolute right-0 z-10 mt-2 w-72 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {companies.map((option) => (
                    <Listbox.Option
                      key={option.empresa}
                      className={({ active }) =>
                        classNames(
                          active ? "bg-[#D2E28A] text-black" : "text-black",
                          "cursor-default select-none p-4 text-sm"
                        )
                      }
                      value={option}
                    >
                      {({ selected, active }) => (
                        <div className="flex flex-col">
                          <div className="flex justify-between">
                            <p
                              className={
                                selected ? "font-semibold" : "font-normal"
                              }
                            >
                              {option.empresa}
                            </p>
                            {selected ? (
                              <span
                                className={
                                  active ? "text-white" : "text-[#D2E28A]"
                                }
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </div>
                        </div>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              )}
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
