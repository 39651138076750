import React from "react";

export default function Tab({ icono, titulo, url, selected, onClick }) {
  const handleClick = () => {
    onClick({ icono, titulo, url });
  };

  return (
    <button
      className={`flex justify-center items-center py-3 px-4 rounded-[5px] ${
        selected ? "bg-[#F5F7FC] text-[#8C9C7C]" : "bg-white text-[#BCC2D2]"
      }`}
      onClick={handleClick}
    >
      <span
        className={`tab-icon mr-2 ${
          selected
            ? "fill-[#8C9C7C] stroke-[#8C9C7C]"
            : "fill-[#BCC2D2] stroke-[#BCC2D2]"
        }`}
        dangerouslySetInnerHTML={{ __html: icono }}
      />
      <span className="tab-title">{titulo}</span>
    </button>
  );
}
