import React, { useState, useEffect } from "react";
import "../App.css";
import logo from "../assets/images/logo.svg";
import Tab from "../components/Tab";
import Profile from "../components/Profile";
import api from "../api";
import DateFilter from "../components/DateFilter";
import CompanyFilter from "../components/CompanyFilter";

export default function Dashboard() {
  const [username, setUsername] = useState("");
  const [profilePicture, setProfilePicture] = useState(null);
  const [permissions, setPermissions] = useState({
    is_staff: null,
    is_superuser: null,
  });
  const [empresaId, setEmpresaId] = useState(null);
  const [tabs, setTabs] = useState(undefined);
  const [selectedTab, setSelectedTab] = useState(null);
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState({
    startDate: null,
    endDate: null,
  });
  const [dateTo, setDateTo] = useState(null);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateFilter, setDateFilter] = useState(null);
  const [companyFilter, setCompanyFilter] = useState(null);

  useEffect(() => {
    const storedUser = localStorage.getItem("PDuserData");
    if (storedUser) {
      const userData = JSON.parse(storedUser);
      setUsername(`${userData.firstName} ${userData.lastName}`);
      setEmpresaId(userData.empresa.id);
      setProfilePicture(userData.profilePicture);
      setPermissions({
        is_staff: userData.isStaff,
        is_superuser: userData.isSuperuser,
      });
    }
  }, []);

  useEffect(() => {
    if (permissions.is_staff !== null && permissions.is_superuser !== null) {
      const fetchData = async () => {
        const response = await api.tabConfiguration.getMulti();
        if (permissions.is_staff || permissions.is_superuser) {
          setTabs(response);
          setSelectedTab(response[0]);
          setDateFilter(response[0].filtroFechas);
          setCompanyFilter(response[0].filtroEmpresas);
        } else {
          const filteredTabs = response.filter((tab) => !tab.soloAdmin);
          setTabs(filteredTabs);
          setSelectedTab(filteredTabs[0]);
          setDateFilter(filteredTabs[0].filtroFechas);
          setCompanyFilter(filteredTabs[0].filtroEmpresas);
        }
        setLoading(false);
      };
      fetchData();
    }
  }, [permissions]);

  useEffect(() => {
    const startDate = new Date(
      new Date().setFullYear(new Date().getFullYear() - 1)
    );
    const endDate = new Date();
    setValue({
      startDate,
      endDate,
    });
    const startDateTimestamp = startDate.getTime();
    const endDateTimestamp = endDate.getTime();
    setDateFrom(startDateTimestamp.toString());
    setDateTo(endDateTimestamp.toString());
  }, []);

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    setDateFilter(tab.filtroFechas);
    setCompanyFilter(tab.filtroEmpresas);
  };

  const handleValueChange = (newValue) => {
    setValue(newValue);
    const startDateTimestamp = new Date(newValue.startDate).getTime();
    const endDateTimestamp = new Date(newValue.endDate).getTime();
    setDateFrom(startDateTimestamp.toString());
    setDateTo(endDateTimestamp.toString());
  };

  return (
    <div>
      {loading ? (
        <div>Cargando</div>
      ) : (
        <div className="flex h-screen w-screen">
          {/* Left side */}
          <div style={{ width: "428px" }}>
            {/* Logo */}
            <div className="flex justify-center">
              <img src={logo} alt="Logo" />
            </div>
            <div className="flex justify-center px-5">
              <hr className="border-gray-300 border-t-2 w-full" />
            </div>
            {/* Tabs */}
            <div className="flex flex-col my-4 px-5 font-bold leading-7">
              {tabs.map((tab) => (
                <Tab
                  key={tab.titulo}
                  icono={tab.icono}
                  titulo={tab.titulo}
                  url={tab.url}
                  selected={selectedTab && selectedTab.titulo === tab.titulo}
                  onClick={() => handleTabClick(tab)}
                />
              ))}
            </div>
          </div>

          {/* Vertical line */}
          <div className="bg-e8ebf3 w-px h-full shrink-0"></div>

          {/* Right side */}
          <div className="w-screen h-screen flex flex-col ml-6">
            {/* Profile */}
            <Profile username={username} profilePicture={profilePicture} />

            <div className="p-4 flex flex-col h-full">
              {/* Graph title */}
              <div className="mb-4">
                <div className="inline-block">
                  <h2 className="text-3xl font-bold relative">
                    {selectedTab && selectedTab.titulo}
                    <span className="absolute top-9 left-0 h-1 bg-d2e28a w-full"></span>
                  </h2>
                </div>
              </div>

              {/* Filters */}
              <div className="w-full">
                <div className="mb-3">
                  <div className="mb-2">
                    <h2>Filtrar por:</h2>
                  </div>
                  <div className="flex flex-row">
                    {dateFilter ? (
                      <div className="w-[265px] mr-2">
                        <DateFilter
                          value={value}
                          handleValueChange={handleValueChange}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    <div>
                      {companyFilter &&
                      (permissions.is_staff || permissions.is_superuser) ? (
                        <CompanyFilter setEmpresaId={setEmpresaId} />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* Grafana graph */}
              {dateFrom && dateTo && (
                <div className="flex-grow">
                  {selectedTab && (
                    <iframe
                      title="Parte Diario - Grafana Graph"
                      src={`${selectedTab.url}&var_empresa_id=${empresaId}&from=${dateFrom}&to=${dateTo}&theme=light&kiosk`}
                      width="100%"
                      height="100%"
                    ></iframe>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
