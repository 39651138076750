import { createContext, useState, useContext } from "react";
import axios from "axios";
import api from "../api";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  let accessToken = localStorage.getItem("PDaccessToken");
  const [isAuthenticated, setIsAuthenticated] = useState(
      accessToken ? true : false
  );
  const [userData, setUserData] = useState(
      localStorage.getItem("PDuserData")
          ? JSON.parse(localStorage.getItem("PDuserData"))
          : null
  );
  let interceptorId = null;
  let responseInterceptorId = null;

  const requestInterceptor = (config) => {
    /* If JWT is not expired, add Authorization header. Else, redirect to login */
    if (accessToken) {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
        return config;
    } else {
        setIsAuthenticated(false);
        localStorage.removeItem("PDaccessToken");
    }
  };

  const responseInterceptor = (error) => {
    if (error.response.status === 401) {
        signOut();
    }
    return Promise.reject(error);
  };

  /**
   * Attempts to log a user into the system
   * @param {String} username username
   * @param {*} password user password
   * @returns Object with `status: "success"` if user was logged in or
   * `status: "error"` otherwise.
   * Additonally, a `status: "error"` can contain a `detail` key with extra info.
   */

  const signIn = async (email, password) => {
    try {
        const response = await api.users.getToken(email, password);
        accessToken = response.access;
        localStorage.setItem("PDaccessToken", accessToken);
        interceptorId = axios.interceptors.request.use(requestInterceptor);
        responseInterceptorId = axios.interceptors.response.use(
            (response) => {
                return response;
            },
            responseInterceptor
        );
        const user = await api.users.getMe();
        localStorage.setItem("PDuserData", JSON.stringify(user));
        setIsAuthenticated(true);
        setUserData(user);
        return Promise.resolve({ status: "success", userData: user });
    } catch (e) {
        return Promise.reject({
            status: "error",
            detail: "Invalid credentials",
        });
    }
  };

  const signOut = () => {
    axios.interceptors.request.handlers.forEach((x, idx) => {
        axios.interceptors.request.eject(idx);
    });
    axios.interceptors.response.handlers.forEach((x, idx) => {
        axios.interceptors.response.eject(idx);
    });
    interceptorId = null;
    localStorage.removeItem("PDaccessToken");
    localStorage.removeItem("PDuserData");
    setIsAuthenticated(false);
  };

  const toggleAuthentication = () => {
    if (isAuthenticated) {
        signIn();
    } else {
        signOut();
    }
  };

  // Check that interceptor is added when authentication status changes
  if (isAuthenticated && (!interceptorId || !responseInterceptorId)) {
    interceptorId = axios.interceptors.request.use(requestInterceptor);
    responseInterceptorId = axios.interceptors.response.use((response) => {
        return response;
    }, responseInterceptor);
  }

  const [userId, setUserId] = useState(null);
  const context = {
    isAuthenticated,
    toggleAuthentication,
    signIn,
    signOut,
    userId,
    setUserId,
    userData,
  };
  return (
      <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};

const AuthConsumer = ({ children }) => (
  <AuthContext.Consumer>{children}</AuthContext.Consumer>
);

const useAuth = () => useContext(AuthContext);

export { AuthProvider, AuthConsumer, useAuth };
export default AuthContext;