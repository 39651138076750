import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import logo from "../assets/images/logo.svg";
import api from "../api";

const ChangePassword = () => {
  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [changePasswordError, setChangePasswordError] = useState("");
  const [loading, setLoading] = useState(false);
  const { uuid } = useParams();
  const { token } = useParams();
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!password) {
      setChangePasswordError("Por favor, completa ambos campos.");
      setLoading(false);
      return;
    }

    if (password === confirmPassword) {
      await api.reset_password
        .changePassword(password, uuid, token)
        .then((response) => {
          setLoading(false);
          navigate("/login");
        })
        .catch((error) => {
          console.error("Error:", error);
          setChangePasswordError(
            "Hubo un problema al enviar la solicitud. Por favor, inténtalo de nuevo más tarde."
          );
          setLoading(false);
        });
    } else {
      setChangePasswordError("Las contraseñas no coinciden.");
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <div className="text-center w-[450px]">
        <div className="flex justify-center">
          <img src={logo} className="" alt="Logo" />
        </div>
        <h1 className="font-bold text-3xl py-4">Cambiar contraseña</h1>
        <form onSubmit={handleSubmit} className="w-full">
          <div className="relative mt-2 rounded-md shadow-sm">
            <input
              type="password"
              name="password"
              id="password"
              className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#D2E28A] sm:text-sm sm:leading-6"
              style={{ outline: "none" }}
              placeholder="Nueva contraseña"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div className="relative mt-2 rounded-md shadow-sm mb-5">
            <input
              type="password"
              name="confirmPassword"
              id="confirmPassword"
              className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#D2E28A] sm:text-sm sm:leading-6"
              style={{ outline: "none" }}
              placeholder="Confirmar nueva contraseña"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>

          {changePasswordError && (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-1.5 rounded relative mb-1"
              role="alert"
            >
              <span className="block sm:inline">{changePasswordError}</span>
            </div>
          )}

          <div className="flex items-center justify-center">
            <div className="w-full flex items-center font-bold rounded-lg py-2 px-4 my-4 bg-[#D2E28A]">
              <button
                type="submit"
                className="flex justify-center items-center w-full"
              >
                {loading ? (
                  <div className="w-5 h-6 border-b-2 border-white rounded-full animate-spin"></div>
                ) : (
                  "Cambiar contraseña"
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
