import React, { useState } from "react";
import mail from "../assets/icons/mail.svg";
import logo from "../assets/images/logo.svg";
import api from "../api";
import BackButton from "../components/BackButton";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const recoverPassword = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!email) {
      setLoginErrorMessage("Por favor, ingresa tu correo electrónico");
      setLoading(false);
      return;
    }

    await api.reset_password
      .sendEmail(email)
      .then((response) => {
        setLoading(false);
        setSuccess(true);
        console.log(response);
      })
      .catch((error) => {
        console.error("Error:", error);
        if (
          error.message &&
          error.message.nonFieldErrors &&
          error.message.nonFieldErrors.length > 0
        ) {
          setLoginErrorMessage(error.message.nonFieldErrors[0]);
        } else {
          setLoginErrorMessage(
            "Hubo un problema al enviar la solicitud. Por favor, inténtalo de nuevo más tarde."
          );
        }
        setLoading(false);
      });
  };

  return (
    <div>
      {success ? (
        <div className="flex justify-center items-center h-screen">
          <div className="absolute top-12 left-20">
            <BackButton />
          </div>
          <div className="text-center w-[450px]">
            <div className="flex justify-center">
              <img src={logo} alt="Logo" />
            </div>
            <h1 className="font-bold text-3xl py-4">
              Revisa tu correo electrónico
            </h1>
            <div className="bg-green-100 border border-green-400 text-black px-4 py-1.5 rounded relative mt-5">
              <span className="block sm:inline">
                Hemos enviado un enlace para restablecer tu contraseña a tu
                correo electrónico
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center h-screen">
          <div className="absolute top-12 left-20">
            <BackButton />
          </div>
          <div className="text-center w-[450px]">
            <div className="flex justify-center">
              <img src={logo} alt="Logo" />
            </div>
            <h1 className="font-bold text-3xl py-4">Recuperar contraseña</h1>
            <p className="text-center mb-16">
              Ingresa tu correo electrónico para establecer una nueva contraseña
            </p>
            <form onSubmit={recoverPassword} className="w-full">
              <div className="relative mt-2 rounded-md shadow-sm mb-5 border border-slate-300">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-1.5">
                  <img src={mail} className="" alt="Mail" />
                </div>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 focus:ring-2 focus:ring-inset focus:ring-[#D2E28A]"
                  placeholder="Tu correo electrónico"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{
                    borderColor: "#D2E28A !important",
                    boxShadow: "0 0 0 1px #D2E28A !important",
                    outline: "none",
                  }}
                />
              </div>
              {loginErrorMessage && (
                <div
                  className="bg-red-100 border border-red-400 text-red-700 px-4 py-1.5 rounded relative mb-5"
                  role="alert"
                >
                  <span className="block sm:inline">{loginErrorMessage}</span>
                </div>
              )}
              <div className="text-center rounded-lg py-2 px-2 font-bold bg-[#D2E28A]">
                <button
                  type="submit"
                  className="w-full flex justify-center items-center"
                >
                  {loading ? (
                    <div className="w-5 h-6 border-b-2 border-white rounded-full animate-spin"></div>
                  ) : (
                    "Enviar solicitud"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
